<template>

    <div class="main-body">
        <div class="order-success-wrapper">
            <div class="order-success-box">
                <p class="order-success-text">Payment Successful</p>
                <h1 class="order-title">Thank you for your Order</h1>
                <p class="order-description">We appreciate your order <span class="order-id-link"><a href="/account?ref=orders">#{{orderId}}</a></span>, we're currently processing it. So, hang tight and we'll send you a confirmation very soon.</p>

                <div class="order-items">
                    <ul>
                        <li class="mc-item" v-for="item in orderItems" :key="item">
                            <div>
                                <img class="mc-image" :src="'https://firebasestorage.googleapis.com/v0/b/flikbox-2606.appspot.com/o/assets%2Fimages%2Fproducts%2F' + item.id +'%2F1.jpg?alt=media'" alt="Cart Image">
                            </div>
                            <div class="mc-product-details">
                                <div class="mc-name-price-feat-box">
                                    <div class="mc-name-price-box">
                                        <a :href="'/product/'+item.id"><h3 class="mc-product-name">{{item.name}}</h3></a>
                                        <p class="mc-product-price">${{getItemSubtotal(item.price, item.quantity)}}</p>
                                    </div>
                                    <p class="mc-product-feature">{{item.size}}, {{item.color}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="mc-subtotal-box">
                    <p class="subtotal-header">Subtotal</p>
                    <p class="subtotal-value">${{subTotal}}</p>
                </div>
                <div class="mc-subtotal-box">
                    <p class="subtotal-header">Shipping</p>
                    <p class="subtotal-value">FREE</p>
                </div>

                <div class="mc-total-box">
                    <p>Order Total</p>
                    <p>${{finalPrice}}</p>
                </div>

                <p class="continue-shopping-btn"><a href="/">Continue Shopping ➜</a></p>
            </div>
        </div>
    </div>

</template>

<script>
import { getOrderDetails } from '@/apis/order';

export default {
    name: 'OrderSuccess',
    data(){
        return{
            orderId: undefined,
            orderItems: [],
            subTotal: 0.00,
            finalPrice: 0.00
        }
    },
    mounted(){
        this.checkUrlParams();
        this.getOrderItems();
        this.clearCart();
    },
    methods:{
        checkUrlParams(){
            var url_string = window.location.href;
            var url = new URL(url_string);
            this.orderId= url.searchParams.get("orderId");
        },
        // Get order details from firestore
        async getOrderItems(){
            if(this.orderId!=undefined){
                var orders  = await getOrderDetails(this.orderId);
                this.orderItems = orders["orderedItems"];
                this.calculatePrice();
            }
        },
        // Get Subtotal of each Item
        getItemSubtotal(price, quantity){
            return price*quantity;
        },
        /* Calculate Final Price of all Items */
		calculatePrice(){
			this.subTotal = 0.0;
			for(let i=0;i<this.orderItems.length;++i){
				this.subTotal = this.subTotal + (parseFloat(this.orderItems[i]["price"]) * parseInt(this.orderItems[i]["quantity"]))
			}
			this.finalPrice = (this.subTotal).toFixed(2);
		},
        // Clear Shopping Cart after Payment Confirmation
        clearCart(){
            localStorage.setItem("cartItems", JSON.stringify([]));
            this.$root.$refs['navbar'].$refs["mini-cart"].getCartItems();
            this.$root.$refs['navbar'].getCartItems();
        }
    }
}
</script>

<style scoped>
    .main-body{max-width: 1280px;margin: auto;}
    .order-success-wrapper{position: relative;padding: 0 12px;width: 100%;;margin: 80px 0px;}
    .order-success-box{max-width: 550px;margin: auto;}

    .order-success-text{color: #6366f1;font-size: 13px;font-weight: 600;cursor: pointer;text-transform: uppercase;}
    .order-title{margin: 6px 0px;}
    .order-description{font-size: 15px;line-height: 1.4;font-weight: 400;color: #868686;}
    .order-id-link{color: #6366f1;font-weight: 500;font-size: 14px;cursor: pointer;}
    .order-id-link:hover{text-decoration: underline;}

    .order-items{margin: 48px 0px 16px 0px;}
    .mc-item{padding: 12px 0px;display: flex;}
    .mc-image{height: 90px;object-fit: cover;object-position: center;border-radius: 6px;}
    .mc-product{display: grid;grid-template-columns: 80px auto;width: 100%;grid-gap: 12px;margin-bottom: 12px;padding-bottom: 12px;border-bottom: 1px solid #e9e9e9;}
    .mc-product-details{flex-direction: column;flex: 1 1 0%;display: flex;margin-left: 16px;}
    .mc-name-price-feat-box{position: relative;}
    .mc-name-price-box{line-height: 24px;display: flex;justify-content: space-between;}
    .mc-product-name{font-size: 16px;font-weight: 500;}
    .mc-product-price{margin-left: 16px;font-size: 15px;}
    .mc-product-feature{color: #6b7280;font-size: 14px;line-height: 20px;margin-top: 4px;}

    .mc-subtotal-box, .mc-total-box{flex: 1 1 0%;display: flex;align-items: flex-end;justify-content: space-between;font-weight: 500;padding: 10px 0px;}
    .mc-total-box{border-top: 1px solid #e5e7eb;padding-top: 16px;margin-top: 8px;}
    .subtotal-header{color: #6b7280;font-size: 14px;line-height: 20px;font-weight: 400;}
    .subtotal-value{font-size: 14px;line-height: 20px;}
    .mc-header{text-align: left;line-height: 1.5;font-size: 18px;font-weight: 500;margin: 0;margin-bottom: 20px;}
    .continue-shopping-btn{color: #6366f1;font-size: 14px;font-weight: 600;cursor: pointer;margin-top: 32px;user-select: none;}
    .continue-shopping-btn:hover{text-decoration: underline;}
</style>
